import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function NotFound(props) {
  return (
    <div id="notfound" className="content-page">
      <Container className="d-flex align-items-center justify-content-center vertical-container">
        <Row className="text-center">
          <Col className="display-3">404</Col>
          <Col>
            <Row>
              <Col>Oops! You&rsquo;re lost</Col>
            </Row>
            <Row>
              <Col className="muted-text">The page you are looking for was not found</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NotFound;
