import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./navbar.scss";

class Navbar extends Component {
  menuClicked = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  state = {
    showMenu: false,
  };

  render() {
    let navBarClass = "custom-navbar";
    if (this.props.scrolled === true) {
      navBarClass += " scrolled";
    }

    let showMenuClass = "menu";
    if (this.state.showMenu) {
      showMenuClass += " show";
    }

    return (
      <nav className={navBarClass}>
        <Container fluid className="vertical-wrapper">
          <Row>
            <Col className="d-md-none d-lg-none">
              <FontAwesomeIcon
                icon={solid("bars")}
                className="menu-toggler-icon"
                onClick={this.menuClicked}
              />
            </Col>
            <Col>
              <Link to="/">
                <img
                  src="/logo.png"
                  width="127.5"
                  height="25"
                  alt="Udamo Logo"
                  className="navigation-logo"
                />
              </Link>
            </Col>
            <Col className="text-right">
              <div className="collapsible-items">
                <Link to="/" className="navigation-item">
                  About
                </Link>
                <Link to="/terms" className="navigation-item">
                  Terms
                </Link>
                <Link to="/privacy" className="navigation-item">
                  Privacy
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <div className={showMenuClass}>
          <span className="menu-close" onClick={this.menuClicked}>
            &times;
          </span>
          <Link to="/" className="navigation-item" onClick={this.menuClicked}>
            About
          </Link>
          <Link
            to="/terms"
            className="navigation-item"
            onClick={this.menuClicked}
          >
            Terms
          </Link>
          <Link
            to="/privacy"
            className="navigation-item"
            onClick={this.menuClicked}
          >
            Privacy
          </Link>
        </div>
      </nav>
    );
  }
}

export default Navbar;
