import React, { Component } from "react";
import { Container } from 'react-bootstrap';

class Privacy extends Component {
  render() {
    return (
      <div className="content-page">
        <Container>
          <h1 className="text-center">Privacy policy</h1>
          <div>Last updated: September 12, 2020</div>
          <p>At Udamo Limited (“Udamo”, “us”, “we”, “our”), we believe in transparency and honesty. We hope you will find this privacy policy clear and transparent. Do not hesitate to reach out to us at hello@udamo.com if you have any questions.
          This Privacy Policy (together with our Terms of Use at <a href="https://www.udamo.com/terms">udamo.com/terms</a>, and any other documents referred to in it) sets out the basis upon which any personal information (“Personal Data”) we collect from you, or that you provide to us, in connection with websites, platforms, services and applications that we own or operate and that contain a link to this Privacy Policy (collectively, the “Products”), will be processed by us.
          We will treat your Personal Data in accordance with the EU General Data Protection Regulation (GDPR), the Data Protection Act 2018 and other applicable data protection laws. </p>
          
          <div className="subheading">Purpose of This Privacy Policy</div>
          <div>
            <div className="mb-1">This privacy policy:</div>
            <ul>
              <li>provides you with detailed information about the types of Personal Data we may collect about you;</li>
              <li>explains what we do with that information, how we store that information and keep it secure and safe;</li>
              <li>explains the legal basis under data protection laws for our processing of your personal information; and</li>
              <li>explains what rights you have under data protection laws in relation to your personal information and how you can exercise those rights.</li>
            </ul>
          </div>
          
          <div className="subheading">Who are Udamo?</div>
          <p>Udamo Limited is a company registered under the laws of England and Wales, whose company number is 09453201 and whose registered office is C/O Sch Consultancy Limited 3000 Hillswood Drive, Hillswood Business Park, Chertsey, Surrey, KT16 0RS, United Kingdom. </p>
          
          <div className="subheading">What information we collect</div>
          <div>
            <div className="mb-1">​When you use the Product, we collect the following types of Personal Data from you: </div>
            <ul>
              <li>Contact data, including your name, email address, phone number; </li>
              <li>Technical data such as your IP address, device type, system performance and usage data (“Technical Data”);</li>
              <li>Your responses to questions in the Product.</li>
            </ul>
          </div>
          <p>​Udamo does not collect or process credit or debit card (“Payment Card”) data. Apple and Google collect Payment Card data with respect to in-app purchases made through the Apps, and our payment processor collects Payment Card data with respect to purchases made through the Websites. Such payment processors generally provide us with some limited data related to you, such as a unique, anonymous token that enables you to make additional purchases using the data they’ve stored, and your card’s type, expiration date, billing address, and the last four digits of your card number.​</p>
           
          <div className="subheading">How do we use your Personal Data?</div>
          <div>
            <div className="mb-1">We will only process personal data when the law allows us to. Most commonly, we use Personal Data for the following purposes:</div>
            <ul>
              <li>To register you as a user of our Products and permit you to use it.</li>
              <li>To deliver services that we provide to you and to manage our relationship with you, to meet your needs and to enable our Products to deliver more useful, customised content.</li>
              <li>To improve the quality of our services and the infrastructure that we use to provide such Products and develop new ones.</li>
              <li>To improve security by protecting against fraud and abuse;</li>
              <li>To conduct analytics and measurements so as to better understand how our Products are used.</li>
              <li>To monitor usage of our Products so as to manage capacity and deal with any technical issues that may arise from time to time.</li>
              <li>To produce aggregate usage data (from which individual users cannot be identified) to understand how our Products are used and to provide the same to third parties and group companies who may use it for analytics, trend analysis and to improve and provide the products and services provided by us.</li>
              <li>To inform you about our services, such as letting you know about upcoming services changes, technical issues, improvements or changes to our Terms of Use.</li>
              <li>To develop and carry out marketing activities about our services and to manage our network.</li>
              <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
              <li>Where we need to comply with a legal obligation to provide Personal Data.</li>
            </ul>
          </div>
          
          <div className="subheading">Cookies and tracking</div>
          <div className="mb-3">​A cookie is a small text file stored by your browser on your device when you visit our website. Udamo and our analytics partners use technologies such as cookies, beacons, tags, and scripts to enable a service to recognise your device so you don't have to provide the same data several times during one task for example; to recognize that you may have already given a username and password so you don't need to do it for every page requested, and to measure how people are using the Products.
            Please see our Cookie Policy which explains more about our use of cookies and how you can block or manage them.
            We may also allow third party service providers to use cookies and other technologies to collect Information and to track browsing activity over time and across third party websites such as web browsers used to read our websites, which websites are referring traffic or linking to our Products, and to deliver targeted advertisements to you. We do not control these third party technologies and their use is governed by the privacy policies of third parties using such technologies. For more information about third party advertising networks and similar entities that use these technologies, see <a href="http://www.aboutads.info/consumers">http://www.aboutads.info/consumers</a>, and to opt-out of such ad networks’ and services’ advertising practices, go to <a href="http://www.aboutads.info/consumers">http://www.aboutads.info/consumers</a> and <a href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a>. Once you click the link, you may choose to opt-out of such advertising from all participating advertising companies or only advertising provided by specific advertising companies. Please note that to the extent advertising technology is integrated into the Products, you may still receive advertisements even if you opt-out of tailored advertising. In that case, the ads will just not be tailored. Also, we do not control any of the above opt-out links and are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.
          </div>
          
          <div className="subheading">Our legal basis for processing your data</div>
          <div>
            <div className="mb-1">The law on data protection provides a number of different grounds that a company such as Udamo can rely on to make its processing of personal data lawful. Udamo relies on the following legal grounds to process Personal Data:</div>
            <ul>
              <li>You have consented to our using your Personal Data; before you can begin the course, you are required to agree to this Privacy Policy and the associated Cookie Policy and Terms, which gives us your consent to collect and process Personal Data.</li>
              <li>Our contractual obligations and performance; we may process Personal Data to comply with and perform our obligations and exercise our rights under our contract with you. We also rely on this basis when ascertaining whether or not you are complying with our Terms and enforcing those Terms.</li>
              <li>Our legitimate interests; the law states that in specific situations, we can process Personal Data to pursue our legitimate interests in a way which might reasonably be expected as part of running our business and which does not materially impact the rights, freedoms or interests of our customers. We rely on this basis to use your Personal Data to send you communications and information about other services we offer. We also rely on this basis to process your Personal Data to generate the anonymised data.</li>
              <li>Legal compliance; we may process your Personal Data to comply with any applicable legal obligation, law, regulation, legal process or enforceable governmental request or to detect, prevent or otherwise address fraud or crime prevention.</li>
            </ul>
          </div>
          
          <div className="subheading">Sharing your Personal Data</div>
          <div className="mb-3">We may store your Technical Data on external log storage and with analysis providers. This allows us to improve the service we offer our customers.
            We may disclose or share Personal Data in order to comply with any legal obligation on us or to protect the rights, property, or safety of others. This includes exchanging information with other companies and organisations for the purposes of fraud protection or the prevention of criminal conduct.
            We may disclose Personal Data to a purchaser of Udamo or substantially all of its assets, in which case Personal Data held by Udamo will be one of the transferred assets.
            We won’t share Personal Data with any third party for the purpose of marketing unless you have given your consent to us doing that. If you do consent to receive information about third party products or services, we will provide you with relevant details of the third party (including who they are, where they are based and how they may be contacted) and will explain what Personal Data will be shared with them.
         </div>
          
          <div className="subheading">How we protect your User Personal Data</div>
          <div>We work hard to protect Personal Data from unauthorised access, misuse, alteration, disclosure or destruction. We have put in place appropriate security measures to prevent Personal Data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
            In particular the steps we take to protect Personal Data include:
            <ul>
              <li>The encryption, pseudonymisation and / or anonymisation of Personal Data we process and store, where reasonably feasible.</li>
              <li>The regular monitoring of our systems for possible vulnerabilities and attacks.</li>
              <li>The use of firewalls, web application firewalls, threat detection, vulnerability analysis and traffic encryption using strong protocols and ciphers.</li>
              <li>The restriction of access to Personal Data to Udamo employees, contractors and agents who need to know that information in order to process it and who are subject to strict contractual confidentiality obligations.</li>
              <li>The use of systems which run on industry leading cloud services providers which are compliant with the most rigorous industry standard certifications in order to guard against unauthorised access to systems.</li>
              <li>By putting in place procedures to deal with any suspected personal data breach.</li>
            </ul>  
          </div>
           
          <div className="subheading">Data breaches</div>
          <p>In the unlikely event that there were to be any unauthorised access to (or an event occurs that creates a real risk of any unauthorised access to) any Personal Data which Udamo holds, then we will, if we considers that the such events give rise to a high risk of affected individuals being adversely impacted, notify the affected individuals (and the Information Commissioner) as soon as reasonably practicable.</p>
           
          <div className="subheading">How long we keep your personal data</div>
          <div className="mb-3">To determine the appropriate retention period for any particular type of Personal Data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of such personal data, the purposes for which we process such personal data and whether we can achieve those purposes through other means, and the applicable legal and regulatory requirements.
          We retain Personal Data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements.​
          Personal Data relating to your account is kept for as long as the account is active and is retained for a further 60 days from the date each user’s account is deleted.
          Personal Data collected based on your consent to receive our marketing communications will be kept and used until you withdraw consent or applicable law requires that such data is no longer used.​
          At the end of the retention period, Personal Data will be deleted completely, and if for any reason it can not be deleted, will be replaced with non-identifiable dummy data.
          </div>
          
          <div className="subheading">Communicating with you</div>
          <p> We may use your Personal Data to inform you about our services – for example we may send you emails or electronic notifications letting you know about upcoming service changes, technical issues, improvements or changes to our terms of use. We may also send you emails containing information about products and services we offer or to conduct surveys but we won’t do that if you opted not to receive such emails when you registered with us. Any email of this type that we send you will contain an opt out option, which you can use to tell us that you no longer wish to receive this kind of email. 
          You can ask us or any approved third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.
          </p>
          
          <div className="subheading">Your rights in relation to your Personal Data</div>
          <div className="mb-3">
            <div className="mb-1">We rely on your consent to use your personal information and you can withdraw that consent at any time. You also have the following rights:​</div>
            <ul>
              <li>Right of access - You have the right to know if your Personal Data is being held, what categories of data are held, and to receive a copy of all data about you. This is known as a Data Subject Access Request.</li>
              <li>Right of rectification - You have the right to request that we correct inaccurate personal information concerning you. You can ask us to check if you are unsure.</li>
              <li>Right of erasure - You may request we delete your Personal Data.</li>
              <li>Right to restrict processing – You may ask for our use of your Personal Data to be restricted if there is disagreement about its accuracy or legitimate usage.</li>
              <li>Right to object - You can ask us not to use your Personal Data to communicate with you, or where we are using it on the basis of our legitimate interests or for research or statistical purposes. You may opt-out from email communications by clicking the 'unsubscribe' link in our emails or contact us.</li>
              <li>Right to data portability – Where we are processing your Personal Data by 'automated means', you may ask us to provide your personal information to you or another service provider in a machine-readable format.</li>
              <li>Rights related to automated decision-making – You have certain rights in relation to decisions made solely on the basis of automated processing of your Personal Data that has legal or similar effects on you.</li>
            </ul>          
           
            To exercise one or more of these rights, you can email help@udamo.com. The provision of information to you is provided free of charge/ However, we may charge a reasonable fee if the request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with such a request in these circumstances.​
            We may ask you for additional information to confirm your identity before disclosing Personal Data to you. This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. Typically we will require at least two valid types of data, being the email address that you used to sign up to Product with and details of the devices you used to access our service (for example MAC Address).
            We may also contact you to ask you for further information in relation to your request to speed up our response.
            We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if the request is particularly complex or if you have made a number of ​
          </div>
          
          <div className="subheading">Changes to our privacy policy</div>
          <p>We keep our privacy policy under regular review. Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by email. We encourage you to periodically review this page for the latest data on our privacy practices. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
          
          
          <div className="subheading">Contacting the regulator to make a complaint</div>
          <div className="mb-3">
            ​You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority in relation to data protection issues (<a href="https://www.ico.org.uk">www.ico.org.uk</a>). If you feel that your data has not been handled correctly, or are unhappy with our response to any requests you have made to us regarding our use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office. We would, however, appreciate the chance to deal with any such concerns before you approach the ICO so please contact us in the first instance.The ICO can be contacted by calling 0303 123 1113 or by going online at <a href="https://www.ico.org.uk/concerns">www.ico.org.uk/concerns</a>.
            If you are based outside the UK, you have the right to lodge a complaint with the relevant data protection regulator in your country of residence.​
          </div>
          
          <div className="subheading">Contact Us</div>
          <p>Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to <a href="mailto:hello@udamo.com">hello@udamo.com</a></p>

        </Container>
      </div>
    );
  }
}

export default Privacy;
