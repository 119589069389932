import React from "react";
import { Container } from "react-bootstrap";
import './about.scss';

function About(props) {
  return (
    <div className="content-page">
      <Container className="vertical-container d-flex align-items-center">
        <div id="new-app-text" className="text-center w-100">New app coming soon…</div>
      </Container>
    </div>
  );
}

export default About;
