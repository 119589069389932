import React, { Component } from "react";
import { Container } from 'react-bootstrap';

class Terms extends Component {
  render() {
    return <div  className="content-page">
      <Container>
        <h1 className="text-center">Terms of use</h1>
        <div className="subheading">Introduction</div>
        <p>This Terms of Use (these “Terms”) form an agreement between you and Udamo Limited, a company registered under the laws of England and Wales, whose company number is 09453201 and whose registered office is C/O Sch Consultancy Limited 3000 Hillswood Drive, Hillswood Business Park, Chertsey, Surrey, KT16 0RS, United Kingdom (“Udamo”, “us”, “we”). Udamo supply content, products or services listed on <a href="https://www.udamo.com">www.udamo.com</a> (the “Website”), through applications (the “Apps”) to you (the Website and such content, products, services and the Apps are collectively referred to herein as the “Product” or “Products”). By installing any Product, or otherwise use or access the Products, you indicate that you accept these Terms and agree to be bound by these Terms and all applicable laws, rules and regulations. If you do not agree to these Terms, please refrain from using the Products.</p>
    
        <div className="subheading">Eligibility</div>
        <p>​You must be over the age of 18, or the age of majority in your province, territory or country, to use the Products. If you do not meet these qualifications please do not attempt to access or use the Products.</p>
        
        <div className="subheading">Account</div>
        <p>​​You may sign up as a registered user of the Products free of charge (a “Member”). To become a Member you will be asked to submit certain information about yourself (“User Information”). Our information collection and use policies with respect to the privacy of such User Information are set forth in the <a href="privacy">Privacy Policy</a>.</p>
        <div>
          <div className="mb-1">By providing Registration Information you agree that:</div>
          <ol>
            <li>you are legally capable of entering into binding contracts;</li>
            <li>you are responsible for maintaining the confidentiality of your account and password;</li>
            <li>all such information you provide will be accurate, complete, and current; </li>
            <li>you will maintain and promptly update all such information to keep it accurate, complete, and current;</li>
            <li>you will not provide any information belonging to another person with the intent to impersonate that person; and</li>
            <li>your use of the Products does not violate any applicable law or regulation.</li>
          </ol>
        </div>

        <div className="subheading">Intellectual Property Rights</div>
        <p>Subject to these Terms, Udamo grants you a limited, non-exclusive, revocable license (this “License Agreement”) to make personal non-commercial use of the Products. The rights to download and use the Products are licensed to and no title to the intellectual property in the Product is transferred to you. You acknowledge that title and full ownership rights to the Product will remain the exclusive property of Udamo, and you have no rights in them other than to use them in accordance with this License Agreement or the other Terms.</p>
        <div>
          <div className="mb-1">You agree that you will not and you will not assist or permit any third party to:</div>
          <ol>
            <li>copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of the Services or any part thereof, or use the Product for any commercial purposes. You shall not modify, alter, change or otherwise make any modification to the Product or create derivative works based upon the Product;</li>
            <li>use the Products or any part of them for the purpose of bringing an intellectual property infringement claim against Udamo or to create any tool or software product that can be used to create software applications of any nature whatsoever;</li>
            <li>engage in any activity that interferes with or disrupts the Product (or the servers and networks which are connected to the Product);</li>
            <li>access the Products in a manner intended to avoid incurring fees; and</li>
            <li>use the Products in a way that violates this License Agreement or the other Terms.</li>
          </ol>
        </div>

        <div className="subheading">Medical Disclaimer</div>
        <p>Udamo are not a health care or medical device provider, nor should our Products be considered medical advice. Any advice or other materials in the Products are intended for general information purposes only. They are not intended as a substitute for professional medical advice based on your individual condition and circumstances.</p>
        <p>Your use of the Products are entirely at your own risk and you assume full responsibility for your decisions and actions as a result of using the Products. To the fullest extent permitted by law, we make no representation or warranties about the accuracy, completeness, or suitability for any purpose of the advice, other materials and information published as part of the Products.</p>

        <div className="subheading">Modification to terms</div>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. Any changes we may make to our privacy policy in the future will be posted on this page. You should check this page from time to time and take note of any changes.​</p>

        <div className="subheading">Disclaimer of Warranty</div>
        <p>The Product is provided on an "as is" basis, without warranty of any kind with respect to them, including as to the accuracy, completeness or currency of the Products or their content. We assume no liability or responsibility for any errors or omissions in the content of the Products, or any failures, delays, or interruptions in the provision of the Products. We disclaim and make no express or implied warranties and specifically disclaim the warranties of merchantability, fitness for a particular purpose of the Products to the broadest extent permitted by law. We make no warranties or representations, express or implied, for technical accessibility, fitness or that the operation of the Product will be error-free. We make no warranties or representations that your use of content and information posted on the Products will not infringe rights of third parties. The entire risk as to the quality and performance of the Product is with you. ​</p>

        <div className="subheading">Liability</div>
        <p>To the fullest extent permitted by law, Udamo and its officers, directors, shareholders, affiliates, employees, agents, service providers, contractors, assigns or licensors (collectively, the “Protected Parties”) shall not be liable for any special, incidental or consequential damages or losses, whether direct or indirect, resulting from possession, access, use or malfunction of the Products, including but not limited to damages to property, loss of goodwill, computer or mobile device malfunction and, damages for personal injuries, property damage, lost profits or punitive damages from any causes of action arising out of or related to these Terms or the Product, whether arising in tort (including negligence), contract, strict liability or otherwise and whether or not the Protected Parties has been advised of the possibility of such damages.</p>
        <p>Because some states/countries do not allow certain limitations of liability, this limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. This limitation of liability shall not be applied solely to the extent that any specific provision of this limitation of liability is prohibited by any federal, state, or municipal law, which cannot be preempted. These Terms give you specific legal rights, and you may have other rights that vary from jurisdiction to jurisdiction. In no event shall the Protected Entities liability for all damages (except as required by applicable law) exceed the actual price paid by you for use of the Product.</p>

        <div className="subheading">Indemnification</div>
        <p>You agree to indemnify, defend and hold the Protected Parties harmless from and against any and all damages, losses, and expenses arising directly or indirectly from: (i) your acts and omissions to act in using the Product pursuant to these Terms; or (ii) your breach of any provision of these Terms.</p>

        <div className="subheading">No Waiver</div>
        <p>If we delay exercising or fail to exercise or enforce any right available to us under these Terms, such delay or failure does not constitute a waiver of that right or any other rights under these Terms.​</p>

        <div className="subheading">Force Majeure</div>
        <p>We will not be liable to you for any lack of performance, or the unavailability or failure, of the Products, or for any failure or delay by us to comply with these Terms, where such lack, unavailability or failure arises from any cause beyond our reasonable control.​</p>

        <div className="subheading">Assigns</div>
        <p>You agree that Udamo has the right to assign or sub-contract any or all of its obligations under these Terms. You shall not, without our prior written consent, assign these terms and conditions.</p>

        <div className="subheading">Entire Agreement</div>
        <p>These Terms and any document expressly referred to in them constitute the whole agreement between Udamo and you and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement relating to their subject matter. </p>

        <div className="subheading">Governing law</div>
        <p>These Terms shall be governed by and construed in accordance with English law. You expressly agree that the exclusive jurisdiction for any claim or action arising out of or relating to these Terms or your use of the Products shall lie only in the courts of England and Wales, and you further agree to and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim or action.​</p>

        <div className="subheading">Severability</div>
        <p>​If any provision of these Terms is found unenforceable, then that provision will be severed from these Terms and not affect the validity and enforceability of any remaining provisions.</p>
        <p>These Terms are effective and were last updated on September 12, 2020</p>
      </Container>
    </div>;
  }
}

export default Terms;
