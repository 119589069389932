import React, { Component } from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import About from "./components/About";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import ScrollToTop from "./components/ScrollToTop";

class App extends Component {
  constructor() {
      super();
      this.state = { scroll: false };
  }

  componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUnMount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      if (window.pageYOffset > 0) {
          this.setState({ scroll: true });
      } else {
          this.setState({ scroll: false });
      }
  }

  render() {
      const { scroll } = this.state;
      return (
        <>
          <Router>
            <ScrollToTop>
              <Navbar scrolled={scroll} />
              <Switch>
                <Route exact path="/" component={About} />
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
                <Route component={NotFound} />
              </Switch>
            </ScrollToTop>
          </Router>
        </>
      )
  }
}

export default App;
